<template>
  <v-card>
    <v-card-title>
      <p class="text-center">
        <span class="font-weight-semibold">Datos Abiertos</span>
      </p>
    </v-card-title>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
      </v-sheet>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="fixed-height-card"
          >
            <p class="text-lg-center">
              <span class="font-weight-semibold">Compendio Geográfico Tequila Jalisco 2010</span>
            </p>
            <p class="d-flex justify-center">
              <a
                class="img-compendio"
                href="https://tequila-documentos.s3.us-east-2.amazonaws.com/Compendio+Geogra%CC%81fico+Tequila+Jalisco+2010.pdf"
                target="_blank"
              >
                <v-img
                  class="img-compendio circular-image button-image"
                  :src="require('../../assets/images/docs/compendio.png')"
                  style="height: 180px; width: 180px;"
                ></v-img>
              </a>
            </p>
            <p class="text-lg-center">
              <span class="font-weight-semibold">Fuente: Instituto Nacional de Estadística y Geografía</span>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="fixed-height-card"
          >
            <p class="text-lg-center">
              <span class="font-weight-semibold">Panorama Sociodemográfico Jalisco 2020</span>
            </p>
            <p class="d-flex justify-center">
              <a
                class="img-panorama"
                href="https://tequila-documentos.s3.us-east-2.amazonaws.com/Panorama+Sociodemogra%CC%81fico+Jalisco+2020.pdf"
                target="_blank"
              >
                <v-img
                  class="img-panorama circular-image button-image"
                  :src="require('../../assets/images/docs/panorama.png')"
                  style="height: 180px; width: 180px;"
                ></v-img>
              </a>
            </p>
            <p class="text-lg-center">
              <span class="font-weight-semibold">Fuente: Instituto Nacional de Estadística y Geografía</span>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="fixed-height-card"
          >
            <p class="text-lg-center">
              <span class="font-weight-semibold">Infografía Sociodemografía de Tequila</span>
            </p>
            <p class="d-flex justify-center">
              <a
                class="img-infografia"
                href="https://tequila-documentos.s3.us-east-2.amazonaws.com/Infografi%CC%81a+Sociodemografi%CC%81a+de+Tequila.pdf"
                target="_blank"
              >
                <v-img
                  class="img-infografia button-image"
                  :src="require('../../assets/images/docs/infografia.png')"
                  style="height: 180px; width: 180px;"
                ></v-img>
              </a>
            </p>
            <p class="text-lg-center">
              <span class="font-weight-semibold">Fuente: Instituto Nacional de Estadística y Geografía</span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="fixed-height-card"
          >
            <p class="text-lg-center">
              <span class="font-weight-semibold">Oferta Hotelera Jalisco</span>
            </p>
            <p class="d-flex justify-center">
              <a
                class="img-compendio"
                href="https://tequila-documentos.s3.us-east-2.amazonaws.com/Oferta+Hotelera+Jalisco.xlsx"
                target="_blank"
              >
                <v-img
                  class="img-compendio button-image"
                  :src="require('../../assets/images/docs/hotel.png')"
                  style="height: 180px; width: 180px;"
                ></v-img>
              </a>
            </p>
            <p class="text-lg-center">
              <span class="font-weight-semibold">Fuente: Secretaría de turismo</span>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="fixed-height-card"
          >
            <p class="text-lg-center">
              <span class="font-weight-semibold">Indicadores Demográficos Tequila Jalisco  Base de Datos</span>
            </p>
            <p class="d-flex justify-center">
              <a
                class="img-compendio"
                href="https://tequila-documentos.s3.us-east-2.amazonaws.com/Indicadores_Dem_Tequila.xlsx"
                target="_blank"
              >
                <v-img
                  class="img-compendio button-image"
                  :src="require('../../assets/images/docs/demographic.png')"
                  style="height: 180px; width: 180px;"
                ></v-img>
              </a>
            </p>
            <p class="text-lg-center">
              <span class="font-weight-semibold">Fuente: Consejo Nacional de Población</span>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="fixed-height-card"
          >
            <p class="text-lg-center">
              <span class="font-weight-semibold">Indicadores Demográficos Tequila Jalisco  Metadatos</span>
            </p>
            <p class="d-flex justify-center">
              <a
                class="img-compendio"
                href="https://tequila-documentos.s3.us-east-2.amazonaws.com/Indicadores_Dem_Tequila_metadatos.xlsx"
                target="_blank"
              >
                <v-img
                  class="img-compendio button-image"
                  :src="require('../../assets/images/docs/metadata.png')"
                  style="height: 180px; width: 180px;"
                ></v-img>
              </a>
            </p>
            <p class="text-lg-center">
              <span class="font-weight-semibold">Fuente: Consejo Nacional de Población</span>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      datacollection: null,
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
    this.fillData()
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    fillData() { },
  },
  setup() { },
}
</script>

<style scoped>
.circular-image {
  border-radius: 5%;
}

.button-image {
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.button-image:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5%;
}

.fixed-height-card {
  height: 350px; /* Ajusta este valor según sea necesario */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
